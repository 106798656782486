<template>
  <div class="page-content">
    <page-header title="报价中"
                 self="true" />
    <div class="disbale-inquiry-contanier">
      <div class="warm-text">正在匹配真实价格 预计5秒内完成报价</div>
      <img class="empty_img"
           src="https://wechatdn.yiautos.com/channel_query/v2/car.gif" />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import * as service from "@/api/inquiry";
import { Toast } from "vant";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { setChannelId, getChannelId } from "@/utils/StorageHelper.ts";
import { Dialog } from 'vant';
export default defineComponent({
  setup () {
    const store = useStore();
    const router = useRouter();
    async function submit () {
      try {
        const param = store.state.getQuoteParam;
        const res = await service.getQuoteResult(param);
        if (res.code !== 200) {
          Dialog.alert({
            message: '每天最多进行10次询价，请明天再试',
          }).then(() => {
            // on close
            const id = getChannelId();
            router.push({ path: "/inquiry-index", query: { channelId: id } });
          });
        } else {
          store.commit("setQuoteResult", res.data);
          const id = getChannelId();
          router.replace({ path: "/quotation_result", query: { channelId: id } });
        }

      } catch (error) {
        Toast(error.message);

      }
    }
    onMounted(submit);
    return {
      submit,
    };
  },
});
</script>

<style lang="less" scoped>
@import "../../styles/common.less";
.page-content {
  background: #fff;
  height: 100vh;
}
.disbale-inquiry-contanier {
  .flexible(column, center, center);
  padding-top: 0.7rem;
  .empty_img {
    padding-top: 2rem;
    width: 4rem;
    height: 4rem;
  }
  .warm-text {
    .font-regular(0.28rem, #a2acb1);
    height: 0.42rem;
    line-height: 0.42rem;
    padding-top: 0.7rem;
  }
}
</style>
